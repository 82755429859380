import React, { useState, useEffect, useRef } from 'react'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { Alert, Container, Col, Form, Button, Row } from 'react-bootstrap'
import SignaturePad from 'react-signature-pad-wrapper'
import { getNames } from 'country-list'
import { loadStripe } from '@stripe/stripe-js'
import useLocalStorage from '../hooks/useLocalStorage'
import '../components/form.css'
import './ecommerce.css'

const stripePromise = loadStripe(
  process.env.GATSBY_STRIPE_PKEY ||
    'pk_test_51GyBIKEzILbh7AgZ7NfgOKbKNpLBBuU8k2TWTuae4exnDqacy5FpshizW7YsxIfTkRQn9bmnOKXBRsDsdh83wUbz00s63lUuN8'
)

const siteTitle = 'Ecommerce Pickup and Delivery Service'
const siteDescription =
  'Websites as a Service - The answer to high-quality business websites on a budget'

function ECommercePage({ location }) {
  const signaturePad = useRef(null)
  const [showIsSignaturePadEmpty, setShowIsSignaturePadEmpty] = useState(false)
  const [validated, setValidated] = useState(false)
  const [isFormValid, setIsFormValid] = useState(true)
  const [sendingFormData, setSendingFormData] = useState(false)
  const [showError, setShowError] = useState(false)
  const [processedItems, setProcessedItems] = useState([])

  // Check for previouos form submissions
  const [formSubmission, setFormSubmission] = useLocalStorage('formSubmission')

  const handleNewSubmission = e => {
    e.preventDefault()

    window.localStorage.removeItem('formSubmission')
    window.location.href = '/ecommerce'
  }

  const handleCheckout = async () => {
    const checkout = await fetch(
      `/.netlify/functions/checkout?siteUrl=${location.origin}`
    )
      .then(res => res.json())
      .catch(err => console.log(err))

    const stripe = await stripePromise
    const { error } = await stripe.redirectToCheckout({
      sessionId: checkout.id,
    })

    return [error]
  }

  // Handle form submission
  const handleSubmit = async event => {
    const form = event.currentTarget

    if (form.checkValidity() === false || signaturePad.current.isEmpty()) {
      event.preventDefault()
      event.stopPropagation()
      setIsFormValid(false)
    }

    setValidated(true)
    setShowIsSignaturePadEmpty(signaturePad.current.isEmpty())

    // All set, ready to submit
    if (form.checkValidity() && !signaturePad.current.isEmpty()) {
      event.preventDefault()
      event.stopPropagation()
      setIsFormValid(true)

      let imageBlob = await new Promise(resolve =>
        signaturePad.current.canvas.toBlob(resolve, 'image/jpg')
      )

      const data = new FormData(form)
      data.append('Signature', imageBlob, 'signature.jpg')
      data.append('_nonce', generateNonce())

      setSendingFormData(true)
      const formId = form.getAttribute('data-form-id')
      const url = `https://forms.webriq.com/forms/${formId}/submissions`

      fetch(url, {
        method: 'post',
        body: data,
      })
        .then(res => res.json())
        .then(async result => {
          setProcessedItems(items => [...items, ['✔️ Form data sent.']])
          await sleep(500)
          setProcessedItems(items => [
            ...items,
            ['✔️ Creating checkout payment page.'],
          ])
          await sleep(500)
          setProcessedItems(items => [
            ...items,
            ['✔️ DONE. Redirecting to payment page...'],
          ])
          await sleep(1500)

          const [error] = handleCheckout()

          if (error) {
            setSendingFormData(false)
            setShowError(true)
          }

          // Set local storage for previously submitted form submission
          setFormSubmission(result)
        })
        .catch(err => {
          console.error(err)
          setSendingFormData(false)
          setShowError(true)
        })
    }
  }

  // Set countries
  const [countries, setCountries] = useState([])
  useEffect(() => {
    let countryNames = getNames()
    setCountries(countryNames)
  }, [])
  // useEffect(() => {
  //   function handleKeyUp(e) {
  //     if (e.target.value.length === 0) {
  //       this.classList.remove("active");
  //     } else {
  //       this.classList.add("active");
  //     }
  //   }
  //   var formField = document.getElementsByClassName('form-group')
  //   for (var i = 0; i < formField.length; i++) {
  //     formField[i].addEventListener('keyup', handleKeyUp)
  //   }
  //   for(var e = 0; e < formField.length; e++){
  //     formField[e].addEventListener('change', handleKeyUp);
  //   }
  // })

  // If there's a previous form submission, allow user to continue action
  if (formSubmission) {
    return (
      <Layout location={location}>
        <SEO title={siteTitle} description={siteDescription} />
        <Container>
          <h2>Ecommerce Pickup and Delivery Service</h2>
          <br />
          <p>
            You have successsfully submitted your data previously. Click on the
            button below to continue where you left of and pay?
          </p>
          <br />
          <p>
            <Button type="button" onClick={handleCheckout}>
              Continue and pay
            </Button>
          </p>
          <p>
            Or
            <Button
              variant="link"
              onClick={handleNewSubmission}
              style={{ paddingLeft: '5px' }}
            >
              discard last form submission and create a new one here instead...
            </Button>
          </p>
        </Container>
      </Layout>
    )
  }

  return (
    <Layout location={location} title="YOUR ONLINE STORE for $49/month">
      <SEO title={siteTitle} description={siteDescription} />
      <div className="component">
      <Container>
      <Row>
        <Col xs={`${10} mx-auto`} sm={9}>

        {!isFormValid && !sendingFormData && (
          <Alert variant="danger">
            Please make sure you've filled out the necessary fields properly!
          </Alert>
        )}

        {showError && !sendingFormData && (
          <Alert variant="danger">
            Something went wrong submitting form data. Perhaps check your file
            attachment's file size!{' '}
            <a href="/ecommerce">Click here to start over again...</a>
          </Alert>
        )}

        {sendingFormData && (
          <div id="overlay">
            <div className="spinner"></div>
            <br />
            Please wait a moment...
            <br />
            <br />
            {processedItems.map(item => (
              <p key={item}>{item}</p>
            ))}
          </div>
        )}

        <Form
          data-form-id="db53badd-6325-47bc-94ed-8bdda822df3b"
          data-thankyou-url="ecommerce-thankyou"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          className="style1"
        >
          <Form.Group>
            <Form.Control type="text" name="Shop Name" required />
            <Form.Label htmlFor="Shop Name">
              Shop Name <span>*</span>
            </Form.Label>
          </Form.Group>

          <Form.Row className="align-items-center">
            <Col>
              <Form.Group>
                <Form.Control type="text" name="Firstname" required />
                <Form.Label htmlFor="Firstname">
                  Firstname <span>*</span>
                </Form.Label>
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Control type="text" name="Lastname" required />
                <Form.Label htmlFor="Lastname">
                  Lastname <span>*</span>
                </Form.Label>
              </Form.Group>
            </Col>
          </Form.Row>

          <Form.Group>
            <Form.Control type="email" name="Email" required />
            <Form.Label htmlFor="Email">
              Email <span>*</span>
            </Form.Label>
          </Form.Group>

          <Form.Group>
            <Form.Control type="text" name="Website" required />
            <Form.Label htmlFor="Website">
              Website <span>*</span>
            </Form.Label>
          </Form.Group>

          <Form.Group controlId="formGridAddress2"  className="mb-0">
            <Form.Control
              placeholder="Apartment, studio, or floor"
              type="text"
              name="Address"
            />
            <Form.Label>Address *</Form.Label>
          </Form.Group>
          <Form.Text className="text-muted">Street Address</Form.Text>

          <Form.Group controlId="formGridAddress2">
            <Form.Control
              placeholder="Apartment, studio, or floor"
              name="Address Line 2"
            />
            <Form.Text className="text-muted">Address Line 2</Form.Text>
          </Form.Group>

          <Form.Row className="align-items-center">
            <Col>
              <Form.Group>
                <Form.Control type="text" name="City" required />
                <Form.Label htmlFor="City">
                  City <span>*</span>
                </Form.Label>
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Control
                  type="text"
                  name="State Region Province"
                  required
                />
                <Form.Label htmlFor="State Region Province">
                  State/Region/Province <span>*</span>
                </Form.Label>
              </Form.Group>
            </Col>
          </Form.Row>

          <Form.Row className="align-items-center">
            <Col>
              <Form.Group>
                <Form.Control type="text" name="Zip Code" required />
                <Form.Label htmlFor="Zip Code">
                  Postal / Zip Code <span>*</span>
                </Form.Label>
              </Form.Group>
            </Col>

            <Form.Group>
              <Form.Control
                name="Country"
                as="select"
                value="United States of America"
                required
              >
                <option>-- Select country --</option>
                {countries.map(country => (
                  <option key={country}>{country}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Group>
            <Form.Label>Opening Hours</Form.Label>
            <Form.Control as="textarea" name="Opening Hours" />
          </Form.Group>

          <Form.Group>
            <Form.Label>Take out and delivery instructions</Form.Label>
            <Form.Control
              as="textarea"
              name="Take out and delivery instructions"
            />
          </Form.Group>

          <Form.Group>
            <Form.Text className="text-muted">Payments *</Form.Text>
          </Form.Group>
          <Form.Check
            name="Payments"
            id="Payment at pick up or delivery"
            type="checkbox"
            className="my-1 mr-sm-2"
            label="Payment at pick up or delivery"
            custom
          />
          <Form.Check
            name="Payments"
            id="Credit cards (Stripe)"
            type="checkbox"
            className="my-1 mr-sm-2"
            label="Credit cards (Stripe)"
            custom
          />
          <Form.Check
            name="Payments"
            id="Credit cards (Square)"
            type="checkbox"
            className="my-1 mr-sm-2"
            label="Credit cards (Square)"
            custom
          />

          <Form.Group>
            <Form.Label>Pick-up times</Form.Label>
            <Form.Control as="textarea" name="Pick-up times" />
          </Form.Group>

          <Form.Group>
            <Form.Label>Delivery and take out business logic</Form.Label>
            <Form.Control
              as="textarea"
              name="Delivery and take out business logic"
            />
          </Form.Group>

          <Form.Group>
            <Form.Text className="text-muted">
              Product and price sheet *
            </Form.Text>
            <input
              type="file"
              className="form-control"
              name="Product and Price Sheet"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.Text className="text-muted">Terms and Conditions *</Form.Text>
          </Form.Group>
          <Form.Check
            id="TOS"
            name="Terms and Conditions"
            type="checkbox"
            className="my-1 mr-sm-2"
            label="I accept the Terms and Conditions."
            custom
            required
          />

          <Form.Group>
            <Form.Control type="date" name="Date" required />
            <Form.Text className="text-muted">Date</Form.Text>
          </Form.Group>

          <Form.Group
            style={{
              border: showIsSignaturePadEmpty ? '2px solid #dc3545' : 'none',
              borderRadius: '5px',
            }}
          >
            <Form.Label>Signature *</Form.Label>
            <SignaturePad ref={signaturePad} height={300} />
            {showIsSignaturePadEmpty && (
              <Form.Text className="text-danger text-center">
                Please add your signature here.
              </Form.Text>
            )}
            <a
              href="#clear"
              onClick={e => {
                e.preventDefault()
                signaturePad.current.clear()
              }}
            >
              Clear
            </a>
          </Form.Group>

          <Form.Group className="mt-0">
            <div className="webriq-recaptcha" />
          </Form.Group>

          <Form.Group className="mt-0">
            <Button type="submit">Submit</Button>
          </Form.Group>
        </Form>
        </Col>
        </Row>
      </Container>
      </div>
    </Layout>
  )
}

export default ECommercePage

const generateNonce = () => {
  return (
    Math.random()
      .toString(36)
      .substring(2, 15) +
    Math.random()
      .toString(36)
      .substring(2, 15)
  )
}

const sleep = m => new Promise(r => setTimeout(r, m))
